import * as yup from "yup";

import { CAMPAIGN, CAMPAIGN_ALIAS, Campaign, TEMPLATE, TEMPLATE_ALIAS, Template } from "src/types";
import { NativeTemplateType } from "src/hooks/apis/placements/useNativeTemplateList";

import Banner320x50 from "src/assets/images/banner-320x50-mobile.png";
import Banner320x200 from "src/assets/images/banner-320x200-mobile.png";
import InterstitialBanner from "src/assets/images/interstitial-banner-mobile.png";
import Native from "src/assets/images/native-mobile.png";
import RewardVideo from "src/assets/images/reward-video-mobile.png";
import InterstitialVideo from "src/assets/images/interstitial-video-mobile.png";
import Splash from "src/assets/images/splash.png";
import BottomModal from "src/assets/images/bottom-modal.png";

/** 캠페인 타입에 해당하는 미리보기 이미지 */
export const previewImgByCampaign = {
  [CAMPAIGN.Banner]: (id?: number) => (id === 2 ? Banner320x200 : Banner320x50),
  [CAMPAIGN.Interstitial]: InterstitialBanner,
  [CAMPAIGN.Native]: Native,
  [CAMPAIGN.RewardVideo]: RewardVideo,
  [CAMPAIGN.InterstitialVideo]: InterstitialVideo,
  [CAMPAIGN.FrontVideo]: "",
  [CAMPAIGN.Splash]: Splash,
  [CAMPAIGN.BottomModal]: BottomModal,
  [CAMPAIGN.InstreamVideo]: RewardVideo,
  [CAMPAIGN.WebBanner]: "",
  [CAMPAIGN.OneStore]: "",
  [CAMPAIGN.RewardCPM]: "",
  [CAMPAIGN.RewardCPC]: "",
} as const;

export type DefaultTemplateOption = {
  templateNo: Template;
  name: string;
};

/** 컴패니언, 배너 네이티브, 혹은 전면 네이티브 캠페인 생성 시 사용되는 기본 템플릿 옵션 반환 */
export const getDefaultTemplateOption = (
  nativeTemplateType:
    | NativeTemplateType.COMPANION
    | NativeTemplateType.BANNER_SMALL
    | NativeTemplateType.BANNER_MEDIUM
    | NativeTemplateType.BANNER_LARGE
    | NativeTemplateType.INTERSTITIAL
): DefaultTemplateOption => {
  switch (nativeTemplateType) {
    case NativeTemplateType.COMPANION:
      return {
        templateNo: TEMPLATE.COMPANION_A,
        name: TEMPLATE_ALIAS[TEMPLATE.COMPANION_A],
      };

    case NativeTemplateType.BANNER_SMALL:
      return {
        templateNo: TEMPLATE.BANNER_SMALL_A,
        name: TEMPLATE_ALIAS[TEMPLATE.BANNER_SMALL_A],
      };

    case NativeTemplateType.BANNER_MEDIUM:
      return {
        templateNo: TEMPLATE.BANNER_MEDIUM_A,
        name: TEMPLATE_ALIAS[TEMPLATE.BANNER_MEDIUM_A],
      };

    case NativeTemplateType.BANNER_LARGE:
      return {
        templateNo: TEMPLATE.BANNER_LARGE_A,
        name: TEMPLATE_ALIAS[TEMPLATE.BANNER_LARGE_A],
      };

    case NativeTemplateType.INTERSTITIAL:
      return {
        templateNo: TEMPLATE.INTERSTITIAL_A,
        name: TEMPLATE_ALIAS[TEMPLATE.INTERSTITIAL_A],
      };

    default:
      throw new Error("템플릿이 존재하지 않습니다.");
  }
};

/** 네이티브 템플릿에 해당하는 미리보기 이미지 사이즈 반환 */
export const getPreviewImgSize = (template: Template) => {
  switch (template) {
    case TEMPLATE.COMPANION_A:
    case TEMPLATE.COMPANION_B:
      return { width: 180, height: 385 };

    case TEMPLATE.BANNER_SMALL_A:
      return { width: 320, height: 50 };

    case TEMPLATE.BANNER_MEDIUM_A:
    case TEMPLATE.BANNER_MEDIUM_B:
    case TEMPLATE.BANNER_MEDIUM_C:
      return { width: 320, height: 100 };

    case TEMPLATE.BANNER_LARGE_A:
    case TEMPLATE.BANNER_LARGE_B:
      return { width: 320, height: 250 };

    case TEMPLATE.INTERSTITIAL_A:
    case TEMPLATE.INTERSTITIAL_B:
      return { width: 320, height: 480 };

    case TEMPLATE.NATIVE_NO_TEMPLATE:
      return { width: 180, height: 385 };

    case TEMPLATE.NATIVE_COMMON_A:
    case TEMPLATE.NATIVE_COMMON_B:
      return { width: 320, height: 280 };

    case TEMPLATE.NATIVE_ADFIT:
      return { width: 320, height: 80 };

    case TEMPLATE.NATIVE_NAM_XSMALL:
      return { width: 320, height: 50 };

    case TEMPLATE.NATIVE_NAM_SMALL:
      return { width: 320, height: 80 };

    case TEMPLATE.NATIVE_NAM_MEDIUM:
      return { width: 320, height: 100 };

    case TEMPLATE.NATIVE_NAM_LARGE:
      return { width: 320, height: 150 };

    case TEMPLATE.NATIVE_NAM_IMG_BANNER_A:
    case TEMPLATE.NATIVE_NAM_IMG_BANNER_B:
    case TEMPLATE.NATIVE_NAM_IMG_BANNER_C:
    case TEMPLATE.NATIVE_NAM_IMG_BANNER_D:
      return { width: 360, height: 185 };

    default:
      throw new Error("잘못된 템플릿 정보입니다.");
  }
};

export const companionValidationSchema = yup.object({
  name: yup.string().required("플레이스먼트 명을 입력해주세요."),
  type: yup.number().oneOf([CAMPAIGN.Native]).required("광고형식을 선택해주세요."),
  bidfloorStatus: yup.boolean(),
  bidfloor: yup.number().when("bidfloorStatus", {
    is: true,
    then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
  }),
  cpcBidfloorStatus: yup.boolean(),
  cpcBidfloor: yup.number().when("cpcBidfloorStatus", {
    is: true,
    then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
  }),
  templateNo: yup
    .number()
    .oneOf([TEMPLATE.COMPANION_A, TEMPLATE.COMPANION_B])
    .required("광고 템플릿을 선택해주세요."),
});

export const subNativeValidationSchema = yup.object({
  name: yup.string().required("플레이스먼트 명을 입력해주세요."),
  type: yup.number().oneOf([CAMPAIGN.Native]).required("광고형식을 선택해주세요."),
  bidfloorStatus: yup.boolean(),
  bidfloor: yup.number().when("bidfloorStatus", {
    is: true,
    then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
  }),
  cpcBidfloorStatus: yup.boolean(),
  cpcBidfloor: yup.number().when("cpcBidfloorStatus", {
    is: true,
    then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
  }),
  nativeTemplateType: yup
    .number()
    .oneOf([
      NativeTemplateType.BANNER_SMALL,
      NativeTemplateType.BANNER_MEDIUM,
      NativeTemplateType.BANNER_LARGE,
      NativeTemplateType.INTERSTITIAL,
    ])
    .required(),
  templateNo: yup
    .number()
    .required("광고 템플릿을 선택해주세요.")
    .test("isValidTemplateNo", "잘못된 템플릿입니다.", (value, context) => {
      const { nativeTemplateType } = context.parent as { nativeTemplateType: NativeTemplateType };

      if (typeof value !== "number") {
        return false;
      }

      const _value = value as Template;

      if (nativeTemplateType === NativeTemplateType.BANNER_SMALL) {
        return _value === TEMPLATE.BANNER_SMALL_A;
      }

      if (nativeTemplateType === NativeTemplateType.BANNER_MEDIUM) {
        const validTemplateList = [
          TEMPLATE.BANNER_MEDIUM_A,
          TEMPLATE.BANNER_MEDIUM_B,
          TEMPLATE.BANNER_MEDIUM_C,
        ] as Template[];

        return validTemplateList.includes(_value);
      }

      if (nativeTemplateType === NativeTemplateType.BANNER_LARGE) {
        const validTemplateList = [
          TEMPLATE.BANNER_LARGE_A,
          TEMPLATE.BANNER_LARGE_B,
          TEMPLATE.NATIVE_NAM_IMG_BANNER_A,
          TEMPLATE.NATIVE_NAM_IMG_BANNER_B,
          TEMPLATE.NATIVE_NAM_IMG_BANNER_C,
          TEMPLATE.NATIVE_NAM_IMG_BANNER_D,
        ] as Template[];
        return validTemplateList.includes(_value);
      }

      if (nativeTemplateType === NativeTemplateType.INTERSTITIAL) {
        const validTemplateList = [TEMPLATE.INTERSTITIAL_A, TEMPLATE.INTERSTITIAL_B] as Template[];
        return validTemplateList.includes(_value);
      }

      return false;
    }),
});

export const isSizeRequired = (type: Campaign) =>
  [CAMPAIGN.Banner, CAMPAIGN.Interstitial].some((v) => v === type);

export const isMinViewTimeRequired = (type: Campaign) =>
  [CAMPAIGN.Interstitial, CAMPAIGN.InterstitialVideo].some((v) => v === type);

export const isComponentRequired = (type: Campaign, template: Template) =>
  type === CAMPAIGN.Native && template === TEMPLATE.NATIVE_NO_TEMPLATE;

export const isMuteRequired = (type: Campaign) =>
  [CAMPAIGN.InterstitialVideo, CAMPAIGN.RewardVideo].some((v) => v === type);

export const isNativeCampaign = (type: Campaign) => type === CAMPAIGN.Native;

export const campaignValidationSchema = yup.object({
  type: yup.number().required("광고형식을 선택해주세요."),
  size: yup.number().when("type", {
    is: isSizeRequired,
    then: yup.number().required("사이즈를 선택해주세요."),
  }),
  minViewTime: yup.number().when("type", {
    is: isMinViewTimeRequired,
    then: yup.number().required("최소 광고 유지 시간을 선택해주세요."),
  }),
  iconImageRequired: yup.boolean(),
  titleRequired: yup.boolean(),
  descRequired: yup.boolean(),
  mainImageRequired: yup.boolean(),
  ctatextRequired: yup.boolean(),
  mute: yup.boolean(),
  templateNo: yup.number().when("type", {
    is: isNativeCampaign,
    then: yup
      .number()
      .required()
      .oneOf([
        TEMPLATE.NATIVE_NO_TEMPLATE,
        TEMPLATE.NATIVE_COMMON_A,
        TEMPLATE.NATIVE_COMMON_B,
        TEMPLATE.NATIVE_ADFIT,
        TEMPLATE.NATIVE_NAM_XSMALL,
        TEMPLATE.NATIVE_NAM_SMALL,
        TEMPLATE.NATIVE_NAM_MEDIUM,
        TEMPLATE.NATIVE_NAM_LARGE,
      ]),
  }),
});

export const campaignTypeList = [
  {
    type: CAMPAIGN.Banner,
    name: CAMPAIGN_ALIAS[CAMPAIGN.Banner],
  },
  {
    type: CAMPAIGN.Interstitial,
    name: CAMPAIGN_ALIAS[CAMPAIGN.Interstitial],
  },
  {
    type: CAMPAIGN.Native,
    name: CAMPAIGN_ALIAS[CAMPAIGN.Native],
  },
  {
    type: CAMPAIGN.InterstitialVideo,
    name: CAMPAIGN_ALIAS[CAMPAIGN.InterstitialVideo],
  },
  {
    type: CAMPAIGN.RewardVideo,
    name: CAMPAIGN_ALIAS[CAMPAIGN.RewardVideo],
  },
  {
    type: CAMPAIGN.Splash,
    name: CAMPAIGN_ALIAS[CAMPAIGN.Splash],
  },
  {
    type: CAMPAIGN.BottomModal,
    name: CAMPAIGN_ALIAS[CAMPAIGN.BottomModal],
  },
  {
    type: CAMPAIGN.InstreamVideo,
    name: CAMPAIGN_ALIAS[CAMPAIGN.InstreamVideo],
  },
];
