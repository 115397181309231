import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, FocusEvent, MouseEvent, useCallback, useMemo, useState } from "react";
import { AiOutlineHistory } from "react-icons/ai";
import { BsPlusSquare } from "react-icons/bs";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useGetAppMediaList from "src/hooks/apis/media/useGetAppMediaList";
import useGetAppPlacementDetail from "src/hooks/apis/placements/useGetAppPlacementDetail";
import usePutAppPlacement from "src/hooks/apis/placements/usePutAppPlacement";
import useOpenModal from "src/hooks/useOpenModal";
import useUser from "src/hooks/useUser";

import { viewerCompany } from "src/atoms/viewerCompany";
import { TextField } from "src/components/commons";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import {
  APP_PLATFORM_ALIAS,
  Campaign,
  CAMPAIGN,
  CAMPAIGN_ALIAS,
  STATUS,
  TEMPLATE,
  Template,
} from "src/types";

import EditCampaignModal from "./EditCampaignModal";
import EditDspModal from "./EditDspModal";
import EditMediationModal from "./EditMediationModal";
import HistoryModal from "./HistoryModal";
import { placementModalStyle } from "./styles";

export interface CampaignInfo {
  minViewTime?: number;
  iconImageRequired?: boolean;
  titleRequired?: boolean;
  descRequired?: boolean;
  mainImageRequired?: boolean;
  ctatextRequired?: boolean;
  mute?: boolean;
  templateNo?: Template;
}

interface EditPlacementModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const EditPlacementModal = ({ onClose, open }: EditPlacementModalProps) => {
  const { isAdmin } = useUser();
  const [openCampaignModal, onShowEditCampaign, onCloseEditCampaignModal] =
    useOpenModal<string>("");
  const [openHistoryModal, onShowHistory, onCloseHistoryModal] = useOpenModal<string>("");
  const [openDspModal, onShowEditDsp, onCloseEditDspModal] = useOpenModal<string>("");
  const [openMediationModal, onShowEditMediation, onCloseEditMediationModal] =
    useOpenModal<string>("");

  /** 광고 형식 정보 */
  const [campaignInfo, setCampaignInfo] = useState<CampaignInfo>();
  const [company] = useRecoilState(viewerCompany);
  const { data: placementData } = useGetAppPlacementDetail({ placementId: open.key });
  const { data: mediaData } = useGetAppMediaList({ companyKey: company.key });
  const { mutate } = usePutAppPlacement();

  const initialValues = useMemo(() => {
    const { placement } = placementData;
    const { media } = mediaData;
    return {
      name: placement.name,
      id: placement.id,
      mediaKey: placement.media_key,
      mediaName:
        media.find((media) => media.key === placement.media_key)?.name || placement.media_name,
      platformType:
        APP_PLATFORM_ALIAS[
          media.find((media) => media.key === placement.media_key)?.platform_type || 1
        ],
      campaign: CAMPAIGN_ALIAS[placement.type],
      bidfloorStatus: placement.bidfloor_status === STATUS.ACTIVE ? true : false,
      bidfloor: placement.bidfloor,
      cpcBidfloorStatus: placement.cpc_bidfloor_status === STATUS.ACTIVE ? true : false,
      cpcBidfloor: placement.cpc_bidfloor,
      checkViewability: placement.check_viewability,
      isSDKMediation: placement.is_sdk_mediation,
      isRewardCpc: placement.is_reward_cpc || false,
    };
  }, [mediaData, placementData]);

  const validationSchema = yup.object({
    name: yup.string().required("플레이스먼트 명을 입력해주세요."),
    campaign: yup.string().required("광고 형식을 선택해주세요."),
    bidfloorStatus: yup.boolean(),
    bidfloor: yup.number().when("bidfloorStatus", {
      is: true,
      then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
    }),
    cpcBidfloorStatus: yup.boolean(),
    cpcBidfloor: yup.number().when("cpcBidfloorStatus", {
      is: true,
      then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
    }),
    checkViewability: yup.boolean(),
    isRewardCpc: yup.boolean(),
  });

  const { getFieldProps, handleSubmit, touched, errors, setFieldValue, values, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        const { placement } = placementData;
        mutate({
          // 기존 placement 정보
          placementId: open.key,
          bidfloorCurrency: placement.bidfloor_currency,
          cpcBidfloorCurrency: placement.cpc_bidfloor_currency,
          mediaKey: placement.media_key,
          sizeType: placement.size_type,
          type: placement.type,
          // 수정한 placement 정보
          name: values.name,
          bidfloor: +values.bidfloor,
          bidfloorStatus: values.bidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
          cpcBidfloor: +values.cpcBidfloor,
          checkViewability: values.checkViewability,
          cpcBidfloorStatus: values.cpcBidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
          // 수정한 campaign 정보
          ctaTextRequired: campaignInfo?.ctatextRequired || placement.ctatext_required,
          descRequired: campaignInfo?.descRequired || placement.desc_required,
          iconImageRequired: campaignInfo?.iconImageRequired || placement.icon_image_required,
          mainImageRequired: campaignInfo?.mainImageRequired || placement.main_image_required,
          titleRequired: campaignInfo?.titleRequired || placement.title_required,
          minViewTime: campaignInfo?.minViewTime ?? placement.minviewtime,
          mute: campaignInfo?.mute,
          // 수정한 미디에이션 정보
          isSDKMediation: placement.is_sdk_mediation,
          // 컴패니언 네이티브
          companionUse: placement.companion_use,
          // 전면/배너 네이티브
          subNativeUse: placement.sub_native_use,
          // "광고 형식: 네이티브"에서만 사용
          ...(placement.type === CAMPAIGN.Native && {
            templateNo: placement?.template_no || TEMPLATE.NATIVE_NO_TEMPLATE,
          }),
          // Campaign 1~6
          isRewardCpc: values.isRewardCpc,
        });
        onClose();
      },
    });

  const onShowHistoryModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowHistory(e, placementData.placement.id);
    },
    [onShowHistory, placementData.placement.id]
  );

  const onShowEditCampaignModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowEditCampaign(e, placementData.placement.id);
    },
    [onShowEditCampaign, placementData.placement.id]
  );

  const onShowEditDspModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowEditDsp(e, placementData.placement.id);
    },
    [onShowEditDsp, placementData.placement.id]
  );

  const onShowEditMediationModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowEditMediation(e, placementData.placement.id);
    },
    [onShowEditMediation, placementData.placement.id]
  );

  const onSetCampaign = useCallback((values: CampaignInfo) => {
    setCampaignInfo(values);
  }, []);

  const onChangeBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(
        REG_EXP.bidfloor,
        e.target.value.substring(0, e.target.value.length - 1)
      );
      if (+(value || 0) < 1000000) {
        setFieldValue(field, value);
      }
    },
    [setFieldValue]
  );

  const onBlurBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: FocusEvent) => {
      setFieldValue(field, parseFloat(`${values[field] || 0}`));
      handleBlur(e);
    },
    [handleBlur, setFieldValue, values]
  );

  return (
    <Dialog
      css={placementModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-placement"
      aria-describedby="edit placement"
    >
      <DialogTitle id="dialog-title">{placementData.placement.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="매체 명"
                required
                disabled
                {...getFieldProps("mediaName")}
                error={shouldErrorShows("mediaName", touched, errors)}
                helperText={getHelperText("mediaName", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="매체 키"
                required
                disabled
                {...getFieldProps("mediaKey")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플랫폼"
                disabled
                {...getFieldProps("platformType")}
                error={shouldErrorShows("platformType", touched, errors)}
                helperText={getHelperText("platformType", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플레이스먼트 명"
                placeholder="플레이스먼트 명을 입력하세요."
                required
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플레이스먼트 ID"
                required
                disabled
                {...getFieldProps("id")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="광고 형식"
                placeholder="광고 형식을 설정해주세요."
                required
                readOnly
                suffix={
                  <Button color="primary" onClick={onShowEditCampaignModal}>
                    설정하기
                  </Button>
                }
                {...getFieldProps("campaign")}
                error={shouldErrorShows("campaign", touched, errors)}
                helperText={getHelperText("campaign", touched, errors)}
              />
            </Grid>
          </Grid>

          <Grid container mt={1} spacing={2}>
            {/* left */}
            <Grid item xs={6}>
              <Stack spacing={2} alignItems="start">
                {/* bidfloor */}
                <Stack direction="row" spacing={2} alignItems="center">
                  <FormControlLabel
                    className="switch"
                    control={<Switch color="primary" />}
                    label="BidFloor"
                    labelPlacement="start"
                    {...getFieldProps("bidfloorStatus")}
                    checked={values.bidfloorStatus}
                  />
                  {values.bidfloorStatus && (
                    <TextField
                      className="field"
                      required
                      prefix={
                        <span className="ssp-input-currency-prefix">
                          {placementData.placement.bidfloor_currency}
                        </span>
                      }
                      {...getFieldProps("bidfloor")}
                      onChange={onChangeBidfloor("bidfloor")}
                      onBlur={onBlurBidfloor("bidfloor")}
                      error={shouldErrorShows("bidfloor", touched, errors)}
                      helperText={getHelperText("bidfloor", touched, errors)}
                    />
                  )}
                </Stack>

                {/* Viewability */}
                {placementData.placement.type === CAMPAIGN.Banner && (
                  <FormControlLabel
                    className="switch"
                    control={<Switch color="primary" />}
                    label="Viewability"
                    labelPlacement="start"
                    {...getFieldProps("checkViewability")}
                    checked={values.checkViewability}
                  />
                )}

                {/* Reward Banner */}
                {(
                  [
                    CAMPAIGN.Banner,
                    CAMPAIGN.Interstitial,
                    CAMPAIGN.Native,
                    CAMPAIGN.RewardVideo,
                    CAMPAIGN.FrontVideo,
                    CAMPAIGN.InterstitialVideo,
                  ] as Campaign[]
                ).includes(placementData.placement.type) && (
                  <FormControlLabel
                    className="switch"
                    control={
                      <Switch color="primary" checked={values.isRewardCpc} readOnly disabled />
                    }
                    label="Reward Banner"
                    labelPlacement="start"
                    aria-readonly="true"
                  />
                )}
              </Stack>
            </Grid>

            {/* right */}
            {isAdmin && (
              <Grid direction="column" container item xs={6} spacing={2}>
                {/* btn group */}
                <Grid container item className="remote-tools">
                  <Button variant="contained" size="small" onClick={onShowEditDspModal}>
                    <BsPlusSquare />
                    DSP
                  </Button>
                  <Button variant="contained" size="small" onClick={onShowEditMediationModal}>
                    <BsPlusSquare />
                    미디에이션
                  </Button>
                </Grid>

                {/* history */}
                <Grid item xs={3} className="remote-tools">
                  <Button variant="outlined" size="small" onClick={onShowHistoryModal}>
                    <AiOutlineHistory />
                    히스토리
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openCampaignModal.isOpen && (
        <EditCampaignModal
          open={openCampaignModal}
          onClose={onCloseEditCampaignModal}
          onSubmit={onSetCampaign}
        />
      )}
      {openHistoryModal.isOpen && (
        <HistoryModal open={openHistoryModal} onClose={onCloseHistoryModal} />
      )}
      {openDspModal.isOpen && <EditDspModal open={openDspModal} onClose={onCloseEditDspModal} />}
      {openMediationModal.isOpen && (
        <EditMediationModal
          isSdkMediation={placementData.placement.is_sdk_mediation}
          open={openMediationModal}
          onClose={onCloseEditMediationModal}
        />
      )}
    </Dialog>
  );
};

export default EditPlacementModal;
