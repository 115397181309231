import axios from "axios";
import moment from "moment";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import qs from "qs";

console.log(`environment: ${process.env.REACT_APP_PUBLIC_ENVIRONMENT}`);

const API_SOURCE = {
  default: {
    url:
      process.env.REACT_APP_PUBLIC_ENVIRONMENT === "dev"
        ? "https://sspi-op-dev.adpopcorn.com"
        : "https://sspi-op-prod.adpopcorn.com",
    prefix: "/",
  },
  integration: {
    url:
      process.env.REACT_APP_PUBLIC_ENVIRONMENT === "dev"
        ? "https://allapi-dev.adpopcorn.com"
        : "https://allapi-live.adpopcorn.com",
    prefix: "/",
  },
  console: {
    url:
      process.env.REACT_APP_PUBLIC_ENVIRONMENT === "dev"
        ? "https://sspi-console-dev.adpopcorn.com"
        : "https://sspi-console-prod.adpopcorn.com",
    prefix: "/",
  },
};

const API = {
  default: axios.create({
    baseURL: API_SOURCE.default.url + API_SOURCE.default.prefix,
    timeout: 1000 * 60 * 3,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    withCredentials: true,
  }),
  integration: axios.create({
    baseURL: API_SOURCE.integration.url + API_SOURCE.integration.prefix,
    timeout: 1000 * 60 * 3,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    withCredentials: true,
  }),
  console: axios.create({
    baseURL: API_SOURCE.console.url + API_SOURCE.console.prefix,
    timeout: 1000 * 60 * 3,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    withCredentials: true,
  }),
};

let isTokenRefreshing = false;

API.default.interceptors.request.use(
  async (config) => {
    // 30분 이하로 남으면 갱신
    const now = moment();
    const cookie = parseCookies();
    const expiresDate = moment(+cookie.expires);
    const beforeThirtyMin = expiresDate.clone().subtract(30, "minutes");

    if (
      !isTokenRefreshing &&
      cookie.access_token &&
      now.isSameOrAfter(beforeThirtyMin) &&
      now.isBefore(expiresDate) &&
      config.url !== "/auth/token/renew"
    ) {
      isTokenRefreshing = true;
      const {
        data: { data },
      } = await API.default.post("/auth/token/renew");
      API.default.defaults.headers.common["Authorization"] = `Bearer ${data.access_token}`;
      setCookie(null, "access_token", data.access_token, { path: "/" });
      setCookie(null, "expires", (data.access_token_expires_at * 1000).toString(), {
        path: "/",
      });
      isTokenRefreshing = false;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.default.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (error.config.url?.includes("/invite") || error.config.url?.includes("/auth/verify")) {
        throw error;
      }
      // 토큰 만료
      destroyCookie(null, "access_token", { path: "/" });
      destroyCookie(null, "expires", { path: "/" });
      destroyCookie(null, "viewerCompany", { path: "/" });
      localStorage.clear();
    }

    return Promise.reject(error);
  }
);

(function () {
  const cookie = parseCookies();
  if (cookie.access_token) {
    API.default.defaults.headers.common["Authorization"] = `Bearer ${cookie.access_token}`;
  }
})();

// 통합 API 세팅
API.integration.interceptors.request.use(
  async (config) => {
    // 30분 이하로 남으면 갱신
    const now = moment();
    const cookie = parseCookies();
    const expiresDate = moment(+cookie.expires);
    const beforeThirtyMin = expiresDate.clone().subtract(30, "minutes");

    if (
      !isTokenRefreshing &&
      cookie.access_token &&
      now.isSameOrAfter(beforeThirtyMin) &&
      now.isBefore(expiresDate) &&
      config.url !== "/auth/token/renew"
    ) {
      isTokenRefreshing = true;
      const {
        data: { data },
      } = await API.default.post("/auth/token/renew");
      API.integration.defaults.headers.common["Authorization"] = `Bearer ${data.access_token}`;
      setCookie(null, "access_token", data.access_token, { path: "/" });
      setCookie(null, "expires", (data.access_token_expires_at * 1000).toString(), {
        path: "/",
      });
      isTokenRefreshing = false;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.integration.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (error.config.url?.includes("/invite") || error.config.url?.includes("/auth/verify")) {
        throw error;
      }
      // 토큰 만료
      destroyCookie(null, "access_token", { path: "/" });
      destroyCookie(null, "expires", { path: "/" });
      destroyCookie(null, "viewerCompany", { path: "/" });
      localStorage.clear();
    }

    return Promise.reject(error);
  }
);

(function () {
  const cookie = parseCookies();
  if (cookie.access_token) {
    API.integration.defaults.headers.common["Authorization"] = `Bearer ${cookie.access_token}`;
  }
})();

// 콘솔 API 세팅
API.console.interceptors.request.use(
  async (config) => {
    // 30분 이하로 남으면 갱신
    const now = moment();
    const cookie = parseCookies();
    const expiresDate = moment(+cookie.expires);
    const beforeThirtyMin = expiresDate.clone().subtract(30, "minutes");

    if (
      !isTokenRefreshing &&
      cookie.access_token &&
      now.isSameOrAfter(beforeThirtyMin) &&
      now.isBefore(expiresDate) &&
      config.url !== "/auth/token/renew"
    ) {
      isTokenRefreshing = true;
      const {
        data: { data },
      } = await API.default.post("/auth/token/renew");
      API.console.defaults.headers.common["Authorization"] = `Bearer ${data.access_token}`;
      setCookie(null, "access_token", data.access_token, { path: "/" });
      setCookie(null, "expires", (data.access_token_expires_at * 1000).toString(), {
        path: "/",
      });
      isTokenRefreshing = false;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.console.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (error.config.url?.includes("/invite") || error.config.url?.includes("/auth/verify")) {
        throw error;
      }
      // 토큰 만료
      destroyCookie(null, "access_token", { path: "/" });
      destroyCookie(null, "expires", { path: "/" });
      destroyCookie(null, "viewerCompany", { path: "/" });
      localStorage.clear();
    }

    return Promise.reject(error);
  }
);

(function () {
  const cookie = parseCookies();
  if (cookie.access_token) {
    API.console.defaults.headers.common["Authorization"] = `Bearer ${cookie.access_token}`;
  }
})();

export default API;
