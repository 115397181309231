export const DATA_TOOLS_REPORT_TYPE = {
  APP: 0,
  WEB: 1,
  COUPANG: 2,
  BIDDING: 3,
  CREATIVE: 4,
  PERIODIC_BIDDING: 5,
};

export type DataToolsReportType = Union<typeof DATA_TOOLS_REPORT_TYPE>;

export const DATA_TOOLS_REPORT_TYPE_ALIAS = {
  [DATA_TOOLS_REPORT_TYPE.APP]: "APP",
  [DATA_TOOLS_REPORT_TYPE.WEB]: "WEB",
  [DATA_TOOLS_REPORT_TYPE.COUPANG]: "COUPANG",
  [DATA_TOOLS_REPORT_TYPE.BIDDING]: "입찰 보고서",
  [DATA_TOOLS_REPORT_TYPE.CREATIVE]: "크리에이티브 확인",
  [DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING]: "입찰 보고서(기간 선택)",
};

export const PROCEDURE = {
  APP_PLACEMENT: 1,
  APP_PLACEMENT_DSP: 2,
  APP_PLACEMENT_COUNTRY: 3,
  APP_THIRDPARTY_MEDIATION: 4,
  APP_THIRDPARTY_MEDIATION_COUNTRY: 5,
  APP_PLACEMENT_HOURLY: 6,
  APP_PLACEMENT_DSP_HOURLY: 7,
  WEB_PLACEMENT: 8,
  WEB_PLACEMENT_DSP: 9,
  WEB_PLACEMENT_THIRDPARTY: 10,
  WEB_PLACEMENT_WITH_HEADER_BIDDING: 11,
  WEB_THIRDPARTY_MEDIATION: 12,
  WEB_PLACEMENT_HOURLY: 13,
  WEB_PLACEMENT_DSP_HOURLY: 14,
  COUPANG_POSTBACK_DAILY: 15,
  COUPANG_POSTBACK_PUBLISHER_DAILY: 16,
  COUPANG_REPORT_API_DAILY: 17,
  APP_PLACEMENT_DSP_SETTLEMENT: 18,
  APP_DSP_BIDDING_REPORT_DAILY: 19,
  APP_DSP_BIDDING_REPORT_COUNTRY: 20,
  APP_DSP_BIDDING_REPORT_HOURLY: 21,
  APP_THIRDPARTY_MEDIATION_LINEITEM: 22,
};

export type Procedure = Union<typeof PROCEDURE>;

export const PROCEDURE_RANGE = {
  DAILY: 0,
  HOURLY: 1,
};

export type ProcedureRange = Union<typeof PROCEDURE_RANGE>;

export const PROCEDURE_RANGE_ALIAS = {
  [PROCEDURE_RANGE.DAILY]: "일자별",
  [PROCEDURE_RANGE.HOURLY]: "시간대별(실시간)",
};

export const PROCEDURE_TIME_RANGE = {
  AM: 1,
  PM: 2,
};

export type ProcedureTimeRange = Union<typeof PROCEDURE_TIME_RANGE>;

export const PROCEDURE_TIME_RANGE_ALIAS = {
  [PROCEDURE_TIME_RANGE.AM]: "오전(00시 ~ 11시 59분)",
  [PROCEDURE_TIME_RANGE.PM]: "오후(12시 ~ 23시 59분)",
};

export const PROCEDURE_ALIAS = {
  [PROCEDURE.APP_PLACEMENT]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "플레이스먼트별 리포트",
    fileName: "app_placement",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_PLACEMENT_DSP]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "DSP별 리포트",
    fileName: "app_placement_dsp",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_PLACEMENT_COUNTRY]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "국가별 리포트",
    fileName: "app_placement_country",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_THIRDPARTY_MEDIATION]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "미디에이션별 리포트",
    fileName: "app_thirdparty_mediation",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_THIRDPARTY_MEDIATION_COUNTRY]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "미디에이션별/국가별 리포트",
    fileName: "app_thirdparty_mediation_country",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_PLACEMENT_HOURLY]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "실시간) 매체별 리포트",
    fileName: "app_placement_hourly",
    range: PROCEDURE_RANGE.HOURLY,
  },
  [PROCEDURE.APP_PLACEMENT_DSP_HOURLY]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "실시간) DSP별 리포트",
    fileName: "app_placement_dsp_hourly",
    range: PROCEDURE_RANGE.HOURLY,
  },
  [PROCEDURE.WEB_PLACEMENT]: {
    type: DATA_TOOLS_REPORT_TYPE.WEB,
    name: "DSP 합계 리포트",
    fileName: "web_placement",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.WEB_PLACEMENT_DSP]: {
    type: DATA_TOOLS_REPORT_TYPE.WEB,
    name: "DSP별 리포트",
    fileName: "web_placement_dsp",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.WEB_PLACEMENT_THIRDPARTY]: {
    type: DATA_TOOLS_REPORT_TYPE.WEB,
    name: "DSP별/헤더비딩별 리포트",
    fileName: "web_placement_thirdparty",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.WEB_PLACEMENT_WITH_HEADER_BIDDING]: {
    type: DATA_TOOLS_REPORT_TYPE.WEB,
    name: "DSP/헤더비딩 합계 리포트",
    fileName: "web_placement_with_header_bidding",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.WEB_THIRDPARTY_MEDIATION]: {
    type: DATA_TOOLS_REPORT_TYPE.WEB,
    name: "미디에이션별 리포트",
    fileName: "web_thirdparty_mediation",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.WEB_PLACEMENT_HOURLY]: {
    type: DATA_TOOLS_REPORT_TYPE.WEB,
    name: "실시간) 매체별 리포트",
    fileName: "web_placement_hourly",
    range: PROCEDURE_RANGE.HOURLY,
  },
  [PROCEDURE.WEB_PLACEMENT_DSP_HOURLY]: {
    type: DATA_TOOLS_REPORT_TYPE.WEB,
    name: "실시간) DSP별 리포트",
    fileName: "web_placement_dsp_hourly",
    range: PROCEDURE_RANGE.HOURLY,
  },
  [PROCEDURE.COUPANG_POSTBACK_DAILY]: {
    type: DATA_TOOLS_REPORT_TYPE.COUPANG,
    name: "쿠팡 포스트백 일별 리포트 (Pub)",
    fileName: "coupang_postback_daily",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.COUPANG_POSTBACK_PUBLISHER_DAILY]: {
    type: DATA_TOOLS_REPORT_TYPE.COUPANG,
    name: "쿠팡 포스트백 일별 리포트 (Pub/Publisher)",
    fileName: "coupang_postback_publisher_daily",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.COUPANG_REPORT_API_DAILY]: {
    type: DATA_TOOLS_REPORT_TYPE.COUPANG,
    name: "쿠팡 리포트API 일별 리포트 (Pub)",
    fileName: "coupang_report_api_daily",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_PLACEMENT_DSP_SETTLEMENT]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "DSP 정산용 리포트 (UTC 기준)",
    fileName: "DSP_settlement_report_UTC",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_DSP_BIDDING_REPORT_DAILY]: {
    type: DATA_TOOLS_REPORT_TYPE.BIDDING,
    name: "DSP별 입찰 보고서",
    fileName: "app_dsp_bidding_report_daily",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_DSP_BIDDING_REPORT_COUNTRY]: {
    type: DATA_TOOLS_REPORT_TYPE.BIDDING,
    name: "DSP별 국가별 입찰 보고서",
    fileName: "app_dsp_bidding_report_country",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_DSP_BIDDING_REPORT_HOURLY]: {
    type: DATA_TOOLS_REPORT_TYPE.BIDDING,
    name: "실시간) DSP별 입찰 보고서",
    fileName: "app_dsp_bidding_report_hourly",
    range: PROCEDURE_RANGE.HOURLY,
  },
  [PROCEDURE.APP_THIRDPARTY_MEDIATION_LINEITEM]: {
    type: DATA_TOOLS_REPORT_TYPE.APP,
    name: "미디에이션 라인아이템 리포트",
    fileName: "app_thirdparty_mediation_lineitem",
    range: PROCEDURE_RANGE.DAILY,
  },
  [PROCEDURE.APP_DSP_BIDDING_REPORT_DAILY]: {
    type: DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING,
    name: "DSP별 입찰 보고서",
    fileName: "app_dsp_bidding_report_periodic",
    range: PROCEDURE_RANGE.DAILY,
  },
};

export const SEARCH_DATE = {
  YESTERDAY: 0,
  WEEK: 1,
  MONTH: 2,
  CUSTOM: 3,
};

export type SearchDate = Union<typeof SEARCH_DATE>;

export const SEARCH_DATE_ALIAS = {
  [SEARCH_DATE.YESTERDAY]: "어제",
  [SEARCH_DATE.WEEK]: "최근 일주일",
  [SEARCH_DATE.MONTH]: "최근 30일",
  [SEARCH_DATE.CUSTOM]: "맞춤",
};
