import { Box, Button, Grid, MenuItem, Paper, SelectChangeEvent } from "@mui/material";
import moment, { Moment } from "moment";
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { DateField, MultipleSelect, Select } from "src/components/commons";
import useGetPartnerAppList, { PartnerApp } from "src/hooks/apis/report/useGetPartnerAppList";
import usePostOfferwallExcel from "src/hooks/apis/report/usePostOfferwallExcel";
import usePostOfferwallReport from "src/hooks/apis/report/usePostOfferwallReport";
import useToast from "src/hooks/useToast";
import {
  AppPlatform,
  APP_PLATFORM,
  APP_PLATFORM_ALIAS,
  PARTNER,
  Partner,
  PARTNER_ALIAS,
  REPORT,
  Report,
  REPORT_ALIAS,
} from "src/types";
import ReportBoard from "./OfferwallReportBoard";
import ReportChart from "./OfferwallReportChart";
import { offerwallStyle } from "./styles";

const OfferwallReport = () => {
  const toast = useToast();
  const [partnerList, setPartnerList] = useState<Partner[]>([PARTNER.FYBER, PARTNER.TAPJOY]);
  const [mediaList, setMediaList] = useState<PartnerApp[]>([]);
  const [platform, setPlatform] = useState<AppPlatform>(APP_PLATFORM.ALL);
  const [reportType, setReportType] = useState<Report>(REPORT.DAILY);
  const [since, setSince] = useState(moment().subtract(7, "d"));
  const [until, setUntil] = useState(moment().subtract(1, "d"));
  const [country, setCountry] = useState(false);
  const [company] = useRecoilState(viewerCompany);

  const { data: appListData } = useGetPartnerAppList({
    partnerList,
    platform: platform,
    companyKey: company.key,
  });

  // 초기 미디어필터 데이터 세팅
  useEffect(() => {
    setMediaList(appListData.records);
  }, [appListData]);

  const { data, mutate } = usePostOfferwallReport();
  const { mutate: download } = usePostOfferwallExcel();

  const onChangePartnerList = useCallback((values: Partner[]) => {
    setPartnerList(values);
  }, []);

  const onChangeMediaList = useCallback((values: PartnerApp[]) => {
    setMediaList(values);
  }, []);

  const onChangePlatform = useCallback((event: SelectChangeEvent<AppPlatform>) => {
    const value = +event.target.value as AppPlatform;
    setPlatform(value);
  }, []);

  const onChangeReportType = useCallback((event: SelectChangeEvent<Report>) => {
    const value = +event.target.value as Report;
    setReportType(value);
  }, []);

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince(value);
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil(value);
    }
    return;
  }, []);

  const onClickSearch = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (mediaList.length <= 0) {
        toast.warning("매체를 선택해주세요.");
        return;
      }
      mutate({
        companyKey: company.key,
        since,
        until,
        partnerList,
        partnerAppList: mediaList,
        type: reportType,
        byCountry: true,
      });
    },
    [company.key, mediaList, mutate, partnerList, reportType, since, toast, until]
  );

  const onExport = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      download({
        since,
        until,
        partnerList,
        partnerAppList: mediaList,
        type: reportType,
        byCountry: country,
      });
    },
    [download, since, until, partnerList, mediaList, reportType, country]
  );

  const onToggleCountry = useCallback(
    (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setCountry(checked);
    },
    []
  );

  return (
    <Box css={offerwallStyle}>
      <Paper className="content" elevation={2}>
        <Grid className="ssp-tools" container spacing={2}>
          <Grid item xs={2}>
            <Select
              label="리포트"
              placeholder="리포트를 선택해주세요."
              onChange={onChangeReportType}
              value={reportType}
            >
              {Object.entries(REPORT_ALIAS).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              label="플랫폼"
              placeholder="플랫폼을 선택해주세요."
              onChange={onChangePlatform}
              value={platform}
            >
              {Object.entries(APP_PLATFORM_ALIAS).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              className="field"
              options={[PARTNER.FYBER, PARTNER.TAPJOY]}
              label="파트너"
              placeholder="파트너 선택"
              getOptionLabel={(option) => PARTNER_ALIAS[option]}
              onChange={onChangePartnerList}
              value={partnerList}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <MultipleSelect
              className="field"
              options={mediaList}
              label="매체"
              placeholder="매체 선택"
              getOptionLabel={(option) => option.partner_app_name}
              getOptionValue={(option) => option.partner_app_key}
              onChange={onChangeMediaList}
              value={mediaList}
            />
          </Grid>
          <Grid item xs={3}>
            <DateField label="시작일" value={since} maxDate={until} onChange={onChangeSinceDate} />
          </Grid>
          <Grid item xs={3}>
            <DateField
              label="종료일"
              value={until}
              minDate={since}
              maxDate={moment().subtract(1, "d")}
              onChange={onChangeUntilDate}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" sx={{ width: "100%" }} onClick={onClickSearch}>
              조회하기
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ReportChart defaultType="mediaCost" data={data.chart} />
          </Grid>
          <Grid item xs={6}>
            <ReportChart defaultType="impression" data={data.chart} />
          </Grid>
          <Grid item xs={12}>
            <ReportBoard
              data={country ? data.countryTable : data.table}
              country={country}
              onToggleCountry={onToggleCountry}
              onExport={onExport}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default OfferwallReport;
